<template>
  <Dialog
    v-model:visible="showModal"
    modal
    :block-scroll="true"
    @close="$emit('hide')"
  >
    <template #container>
      <div class="max-md:min-w-screen md:min-w-200 md:p-18 relative justify-center bg-white px-6 py-12 max-md:h-screen max-md:w-full">
        <button
          type="button"
          class="absolute right-6 top-6 size-6"
          @click="$emit('hide')"
        >
          <XCloseSvg class="size-6" />
        </button>
        <form
          class="flex flex-col justify-center gap-4 bg-white"
          @submit.prevent="processLogin"
        >
          <h1 class="text-center">
            {{ i18n.trans('Вход') }}
          </h1>
          <p class="text-center text-base">
            {{ i18n.trans('Вы здесь впервые?') }}
            <a
              href="#"
              class="link text-sm font-bold"
              role="button"
              @click="$emit('show-registration-modal')"
            >
              {{ i18n.trans('Зарегистрироваться') }}
            </a>
          </p>
          <div>
            <input
              v-model="form.email"
              type="email"
              placeholder="E-mail"
            />
            <small
              v-if="form.errors.email"
              class="text-danger text-sm"
            >
              {{ form.errors.email }}
            </small>
          </div>
          <a
            role="button"
            class="-mt-3 text-right text-sm font-bold underline"
            @click="$emit('show-sms-login-modal')"
          >
            Войти с номером телефона
          </a>
          <div>
            <input
              v-model="form.password"
              type="password"
              :placeholder="i18n.trans('Пароль')"
            />
            <small
              v-if="form.errors.password"
              class="text-danger text-sm"
            >
              {{ form.errors.password }}
            </small>
          </div>
          <a
            role="button"
            class="-mt-3 text-right text-sm font-bold underline"
            @click="$emit('show-reset-password-modal')"
          >
            {{ i18n.trans('Забыли пароль?') }}
          </a>
          <PrimaryButton
            type="submit"
            class="mt-8"
          >
            {{ i18n.trans('Войти в аккаунт') }}
          </PrimaryButton>

          <SocialAuthButtons />
        </form>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import SocialAuthButtons from '@/Front/Modals/SocialAuthButtons.vue';
import XCloseSvg from 'images/front/icons/x-close.svg';
import Dialog from 'primevue/dialog';
import { ref, watch } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['hide', 'show-registration-modal', 'show-reset-password-modal', 'show-sms-login-modal']);

const showModal = ref(false);
watch(
  () => props.show,
  (value) => {
    showModal.value = value;
  },
  { immediate: true },
);

const form = useForm({
  email: null,
  password: null,
});

const i18n = useTranslations();

function processLogin() {
  form
    .post(route('front.login'))
    .then(() => {
      emits('hide');
      window.location.reload();
    })
    .catch(() => {});
}
</script>

<style scoped lang="scss">
.vue-tel-input {
  @apply rounded-lg border-none bg-neutral-50 p-0;
}
</style>
